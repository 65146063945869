import React, { useEffect } from "react";
// import { OverlayTrigger, Popover } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import PriceCard from "./PriceCard";
import "./Price.css";
import "./BusinessPrice.css";
import StickyPlan from "./StickyPlan";
import "./VpsPrice.css";
function VpsPrice() {
  useEffect(() => {
    var goto = document.getElementById("vps").offsetTop;
    window.scrollTo({ top: goto -100, behavior: "smooth" });
  }, []);
  return (
    <div id="vps">
      <StickyPlan
        plan1={{
          title: "Cloud1",
          price: "25.99",
          period: "Monthly",
          orderlink: "https://billing.isquarehost.com/cart.php?a=add&pid=53",
        }}
        plan2={{
          title: "Cloud2",
          price: "35.99",
          period: "Monthly",
          orderlink: "https://billing.isquarehost.com/cart.php?a=add&pid=54",
        }}
        plan3={{
          title: "Cloud3",
          price: "69.99",
          period: "Monthly",
          orderlink: "https://billing.isquarehost.com/cart.php?a=add&pid=55",
        }}
        plan4={{
          title: "Cloud4",
          price: "149.99",
          period: "Monthly",
          orderlink: "https://billing.isquarehost.com/cart.php?a=add&pid=84",
        }}
      />
      <div className="vps">
        <div className="vps-header">
          <h2>Fully Managed Cloud VPS</h2>
          <p>
            Experience the power of cloud hosting without the hassles of
            managing it yourself. Our in-house experts will take complete care
            of your cloud server.
          </p>
        </div>
        <div className="row m-0">
          {/* <div className="col-lg-3 d-lg-block  m-0 p-1"></div> */}
          <div className="col-lg-12 col-12 p-0 m-0">
            <div className="row m-0">
              <div className="col-md-3 col-12 mx-0 p-1">
                <PriceCard
                  cardHeader="Cloud1"
                  cardSubheader="no hidden cost"
                  cardPrice="25.99"
                  period="Monthly"
                  priceOffer={[
                    "1 separate server",
                    "2 CPU",
                    "2 GB RAM",
                    "50 GB SSD",
                    "1000 GB bandwidth",
                    "dedicated IP",
                    "free optimized control panel",
                  ]}
                  orderlink="https://billing.isquarehost.com/cart.php?a=add&pid=53"
                />
              </div>
              <div className="col-md-3 col-12 mx-0 p-1">
                <PriceCard
                  cardHeader="Cloud2"
                  cardSubheader="no hidden cost"
                  cardPrice="35.99"
                  period="Monthly"
                  priceOffer={[
                    "1 separate server",
                    "2 CPU",
                    "4 GB RAM",
                    "75 GB SSD",
                    "2000 GB bandwidth",
                    "dedicated IP",
                    "free optimized control panel",
                  ]}
                  orderlink="https://billing.isquarehost.com/cart.php?a=add&pid=54"
                />
              </div>
              <div className="col-md-3 m-0 p-1">
                <PriceCard
                  cardHeader="Cloud3"
                  cardSubheader="no hidden cost"
                  cardPrice="69.99"
                  period="Monthly"
                  priceOffer={[
                    "1 separate server",
                    "4 CPU",
                    "8 GB RAM",
                    "150 GB SSD",
                    "3000 GB bandwidth",
                    "dedicated IP",
                    "free optimized control panel",
                  ]}
                  orderlink="https://billing.isquarehost.com/cart.php?a=add&pid=55"
                />
              </div>
              <div className="col-md-3 m-0 p-1">
                <PriceCard
                  cardHeader="Cloud4"
                  cardSubheader="no hidden cost"
                  cardPrice="149.99"
                  period="Monthly"
                  priceOffer={[
                    "1 separate server",
                    "8 CPU",
                    "16 GB RAM",
                    "300 GB SSD",
                    "4000 GB bandwidth",
                    "dedicated IP",
                    "free optimized control panel",
                  ]}
                  orderlink="https://billing.isquarehost.com/cart.php?a=add&pid=84"
                />
              </div>
            </div>
          </div>
          {/* Hosting Features Table */}
          {/* <div className="col-12 m-0">
            <div className="table-responsive-lg">
              <table className="table table-bordered plans-table">
                <tbody>
                  <tr className="title-row">
                    <td colSpan="4">
                      <div className="h4">Hosting Features</div>
                    </td>
                  </tr>
                  <tr className="title-row">
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div> */}
          {/* Hosting Features Table end */}
        </div>
      </div>
    </div>
  );
}

export default VpsPrice;
