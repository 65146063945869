import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import PriceCard from "./PriceCard";
import "./Price.css";
import './BusinessPrice.css'
import StickyPlan from "./StickyPlan";

function BusinessPrice(props) {
  return (
    <div id="business">
      {props.sticky ? (
        <StickyPlan
          plan1={{
            title: "SC 1",
            price: "7.49",
            period: "Monthly",
            orderlink: "https://billing.isquarehost.com/cart.php?a=add&pid=74",
          }}
          plan2={{
            title: "SC 2",
            price: "12.49",
            period: "Monthly",
            orderlink: "https://billing.isquarehost.com/cart.php?a=add&pid=75",
          }}
          plan3={{
            title: "SC 3",
            price: "18.49",
            period: "Monthly",
            orderlink: "https://billing.isquarehost.com/cart.php?a=add&pid=76",
          }}
          plan4={{
            title: "SC 4",
            price: "25.49",
            period: "Monthly",
            orderlink: "https://billing.isquarehost.com/cart.php?a=add&pid=77",
          }}
        />
      ) : (
        ""
      )}

      <div className="business">
        <div className="business-header">
          <h2>Business Cloud hosting with LiteSpeed Server</h2>
          <p>
            Perfect Business hosting plans for you, No Hidden Charges !!! Get
            Free domain with annual plan & its Starting From{" "}
            <strong>$7.49/Month</strong>
          </p>
        </div>
        <div className="row m-0 ">
          {/* <div className="col-lg-3 d-lg-block  m-0 p-0"></div> */}
          <div className="col-lg-12 col-12 p-0 m-0">
            <div className="row m-0">
              <div className="col-md-3 col-12 mx-0 p-1">
                <PriceCard
                  cardHeader="SC 1"
                  cardSubheader="Best for New Website"
                  cardPrice="7.49"
                  period="Monthly"
                  priceOffer={[
                    "3 Website",
                    "5 GB SSD space",
                    "free email",
                    "Free SSL ($11 Value)",
                    "Unlimited Bandwidth",
                    "Unlimited Databases",
                    "Free Migration",
                    "Managed WordPress",
                    "WordPress Acceleration",
                    "SSH Access",
                    "GIT Access",
                    "Powerful Control Panel",
                    "Multiple PHP Versions",
                    "DNS Management",
                  ]}
                  orderlink="https://billing.isquarehost.com/cart.php?a=add&pid=74"
                />
              </div>
              <div className="col-md-3 col-12 mx-0 p-1">
                <PriceCard
                  cardHeader="SC 2"
                  cardSubheader="Best for Mid Website"
                  cardPrice="12.49"
                  period="Monthly"
                  priceOffer={[
                    "5 Website",
                    "15 GB SSD space",
                    "free email",
                    "Free SSL ($11 value)",
                    "Free Domain ($10 value)",
                    "Free Daily Backups ($10 value)",
                    "Unlimited Bandwidth",
                    "Free Migration",
                    "Managed WordPress",
                    "WordPress Acceleration",
                    "SSH Access",
                    "GIT Access",
                    "Powerful Control Panel",
                    "Multiple PHP Versions",
                    "DNS Management",
                  ]}
                  orderlink="https://billing.isquarehost.com/cart.php?a=add&pid=75"
                />
              </div>
              <div className="col-md-3 m-0 p-1">
                <PriceCard
                  cardHeader="SC 3"
                  cardSubheader="Best for Growing Website"
                  cardPrice="18.49"
                  period="Monthly"
                  priceOffer={[
                    "Unlimited Website",
                    "30 GB SSD space",
                    "free email",
                    "Free SSL ($11 value)",
                    "Free Domain ($10 value)",
                    "Free Daily Backups ($10 value)",
                    "Unlimited Bandwidth",
                    "Free Migration",
                    "Managed WordPress",
                    "WordPress Acceleration",
                    "SSH Access",
                    "GIT Access",
                    "Powerful Control Panel",
                    "Multiple PHP Versions",
                    "DNS Management",
                    "Cache Manager",
                    "Access Manager",
                  ]}
                  orderlink="https://billing.isquarehost.com/cart.php?a=add&pid=76"
                />
              </div>
              <div className="col-md-3 m-0 p-1">
                <PriceCard
                  cardHeader="SC 4"
                  cardSubheader="Best for Large Website"
                  cardPrice="25.49"
                  period="Monthly"
                  priceOffer={[
                    "Unlimited Website",
                    "unlimited SSD space",
                    "free email",
                    "Free SSL ($11 value)",
                    "Free Domain ($10 value)",
                    "Free Daily Backups ($10 value)",
                    "Unlimited Bandwidth",
                    "Free Migration",
                    "Managed WordPress",
                    "WordPress Acceleration",
                    "SSH Access",
                    "GIT Access",
                    "Powerful Control Panel",
                    "Multiple PHP Versions",
                    "DNS Management",
                    "Cache Manager",
                    "Access Manager",
                  ]}
                  orderlink="https://billing.isquarehost.com/cart.php?a=add&pid=77"
                />
              </div>
            </div>
          </div>
          {/* Hosting Features Table */}
          {/* <div className="col-12 m-0">
            <div className="table-responsive-lg">
              <table className="table table-bordered plans-table">
                <tbody>
                  <tr className="title-row">
                    <td colSpan="4">
                      <div className="h4">Hosting Features</div>
                    </td>
                  </tr>
                  <tr className="title-row">
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div> */}
          {/* Hosting Features Table end */}
        </div>
      </div>
    </div>
  );
}

export default BusinessPrice;
