import React from 'react'
import "./WeOffer.css"
import shared_cloud from "../images/shared-host.webp"
import vps from "../images/vps.webp";
import cloudmg from "../images/cloud-mg.webp"
function WeOffer() {
    return (
      <section id="weoffer">
        <div className="weoffer mycontainer">
          <div className="weoffer-header">
            <h1 className="section-header section-animation" data-aos="fade-up">
              What We Offer
            </h1>
            <div className="section-header-content">
              <p>
                If you are not satisfied with your existing hosting provider and
                you want to best webhosting for your website then our shared
                cloud hosting plan is really suitable for you.
              </p>
              <p>
                All clients are moving to cloud ,so we are providing managed
                cloud shared hosting which is help to client to manage website
                very fast and response is high than normal shared hosting
              </p>
            </div>
          </div>
          <div className="weoffer-cards">
            {/* card 1 */}
            <div className="weoffer-card" data-aos="zoom-in-right">
              <div className="card-image">
                <img src={shared_cloud} alt="shared-cloud" />
              </div>
              <div className="card-content">
                <div className="weoffer-card-header">business hosting</div>
                <div className="card-subcontent">
                  <p>
                    Experience power of shared cloud hosting without hassle of
                    managing it yourself.
                  </p>
                </div>
              </div>
            </div>
            {/* card 2 */}
            <div className="weoffer-card" data-aos="zoom-in-right">
              <div className="card-image">
                <img src={vps} alt="vps-cloud" />
              </div>
              <div className="card-content">
                <div className="weoffer-card-header">CLOUD VPS HOSTING</div>
                <div className="card-subcontent">
                  <p>
                    Get the best I/O perfomance with our managed virtual private
                    servers (VPS).
                  </p>
                </div>
              </div>
            </div>
            {/* card 3 */}
            <div className="weoffer-card" data-aos="zoom-in-right">
              <div className="card-image">
                <img
                  src="https://img.icons8.com/windows/80/000000/wordpress-simple.png"
                  alt="wordpress cloud"
                />
              </div>
              <div className="card-content">
                <div className="weoffer-card-header">
                  WORDPRESS CLOUD HOSTING
                </div>
                <div className="card-subcontent">
                  <p>
                    Simple, Fast & Secure Managed WordPress Hosting built for
                    wordpress websites of all sizes.
                  </p>
                </div>
              </div>
            </div>
            {/* card 4 */}
            <div className="weoffer-card" data-aos="zoom-in-right">
              <div className="card-image">
                <img src={cloudmg} alt="dedicated-cloud" />
              </div>
              <div className="card-content">
                <div className="weoffer-card-header">CLOUD MANAGEMENT</div>
                <div className="card-subcontent">
                  <p>
                    We manage your own AWS/GCP/AZURE for cost and server
                    optimization.
                  </p>
                </div>
              </div>
            </div>
            {/* card 5 */}
            <div className="weoffer-card" data-aos="zoom-in-right">
              <div className="card-image">
                <img src={shared_cloud} alt="shared-cloud" />
              </div>
              <div className="card-content">
                <div className="weoffer-card-header">cheap shared hosting</div>
                <div className="card-subcontent">
                  <p>
                    Experience power of shared cloud hosting without hassle of
                    managing it yourself.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}

export default WeOffer
