import React from 'react'
import "./HeroSection.css"
import Typical from 'react-typical'
// import HomeBanner from '../images/HomeBanner'
import HomeBanner from "../images/home_banner_svg.svg";
function HeroSection() {
    return (
      <section id="hero" data-aos="fade-left" data-aos-easing="ease-in-out">
        <div className="hero mycontainer">
          <div className="hero-header">
            <div className="hero-head">IT'S PURE</div>
            <div className="hero-head">
              <Typical
                steps={[
                  "SHARED CLOUD",
                  1000,
                  "CLOUD VPS",
                  1000,
                  "DEDICATED CLOUD",
                  1000,
                ]}
                loop={Infinity}
                wrapper="span"
              />
            </div>
            <div className="hero-head">HOSTING SERVICE</div>
          </div>
          <div className="hero-img">
            <img src={HomeBanner} alt="home banner" />
          </div>
        </div>
      </section>
    );
}

export default HeroSection
