import React from 'react'
import PriceCard from './PriceCard'
// import "./Price.css"
function Price() {
    return (
      <section id="price">
        <div className="price mycontainer">
          <div className="price-header">
            <h1 className="section-header">Pricing and Plans</h1>
            <h3>no hidden cost</h3>
          </div>
          <div className="price-cards">
              {/* First Card */}
              <PriceCard
                cardHeader="SC 1"
                cardSubheader="Best Plan for New Website"
                cardPrice="7.49"
                period="Monthly"
                priceOffer={[
                  "3 Website",
                  "5 GB SSD space",
                  "free email",
                  "Free SSL ($11 value)",
                  "Unlimited Bandwidth",
                  "Unlimited Databases",
                  "Free Migration",
                  "Managed WordPress",
                  "WordPress Acceleration",
                  "SSH Access",
                  "GIT Access",
                  "Powerful Control Panels",
                  "Multiple PHP Versions",
                  "DNS Management",
                ]}
                orderlink="https://billing.isquarehost.com/cart.php?a=add&pid=74"
              />
              {/* Second Card */}
              <PriceCard
                cardHeader="SC 2"
                cardSubheader="Best for Mid Website"
                cardPrice="12.49"
                period="Monthly"
                priceOffer={[
                  "5 Website",
                  "15 GB SSD space",
                  "free email",
                  "Free SSL ($11 value)",
                  "Free Domain ($10 value)",
                  "Free Daily Backups ($10 value)",
                  "Unlimited Bandwidth",
                  "Free Migration",
                  "Managed WordPress",
                  "WordPress Acceleration",
                  "SSH Access",
                  "GIT Access",
                  "Powerful Control Panels",
                  "Multiple PHP Versions",
                  "DNS Management",
                ]}
                orderlink="https://billing.isquarehost.com/cart.php?a=add&pid=75"
              />
              {/* First Card */}
              <PriceCard
                cardHeader="SC 3"
                cardSubheader="Best for Growing Website"
                cardPrice="18.49"
                period="Monthly"
                priceOffer={[
                  "Unlimited Website",
                  "30 GB SSD space",
                  "free email",
                  "Free SSL ($11 value)",
                  "Free Domain ($10 value)",
                  "Free Daily Backups ($10 value)",
                  "Unlimited Bandwidth",
                  "Free Migration",
                  "Managed WordPress",
                  "WordPress Acceleration",
                  "SSH Access",
                  "GIT Access",
                  "Powerful Control Panels",
                  "Multiple PHP Versions",
                  "DNS Management",
                  "Cache Manager",
                  "Access Manager",
                ]}
                orderlink="https://billing.isquarehost.com/cart.php?a=add&pid=76"
              />
              {/* Second Card */}
              <PriceCard
                cardHeader="SC 4"
                cardSubheader="Best for Large Website"
                cardPrice="25.49"
                period="Monthly"
                priceOffer={[
                  "Unlimited Website",
                  "unlimited SSD space",
                  "free email",
                  "Free SSL ($11 value)",
                  "Free Domain ($10 value)",
                  "Free Daily Backups ($10 value)",
                  "Unlimited Bandwidth",
                  "Free Migration",
                  "Managed WordPress",
                  "WordPress Acceleration",
                  "SSH Access",
                  "GIT Access",
                  "Powerful Control Panels",
                  "Multiple PHP Versions",
                  "DNS Management",
                  "Cache Manager",
                  "Access Manager",
                ]}
                orderlink="https://billing.isquarehost.com/cart.php?a=add&pid=77"
              />
            </div>
          </div>
      </section>
    );
}

export default Price
