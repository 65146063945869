import React, { Component } from 'react'
import "./Blog.css"
export class Blog extends Component {
    render() {
        return (
          <section id="blog">
            
          </section>
        );
    }
}

export default Blog
