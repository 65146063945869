import React, { useState,useEffect } from "react";
import './StickyPlan.css'
function StickyPlan({plan1,plan2,plan3,plan4}) {
    const [plans, setPlans] = useState(false);
    useEffect(() => {
      window.addEventListener("scroll", showStickyPlans);
    }, [plans])
    const showStickyPlans = () => {
      if (document.body.clientWidth < 756) {
        if (window.scrollY >= 3050) {
          setPlans(true);
        } else {
          setPlans(false);
        }
      } else if (document.body.clientWidth >= 756) {
        if (window.scrollY >= 1200 ) {
          setPlans(true);
        } else {
          setPlans(false);
        }
      } else {
        setPlans(false);
      }
    };
  
    return (
      <div
        id="sticky-plans"
        className={
          plans ? "sticky-plans show fixed-top" : "sticky-plans fixed-top"
        }
      >
        <div className="row m-0">
          {/* <div className="col-lg-3 d-lg-block m-0 p-0 "></div> */}
          <div className="col-lg-12 col-12">
            <div className="row m-0 px-0">
              <div className="col-3 px-0">
                <div className="price-card-header">
                  <h3>{plan1.title}</h3>
                  <div className="price-card-tag">
                    <span className="price-tag">$ {plan1.price}</span>
                    <br />
                    <span className="period">{plan1.period}</span>
                  </div>
                  <a href={plan1.orderlink} className="order-btn">
                    Order Now
                  </a>
                </div>
              </div>
              <div className="col-3 px-0">
                <div className="price-card-header">
                  <h3>{plan2.title}</h3>
                  <div className="price-card-tag">
                    <span className="price-tag">$ {plan2.price}</span>
                    <br />
                    <span className="period">{plan2.period}</span>
                  </div>
                  <a href={plan2.orderlink} className="order-btn">
                    Order Now
                  </a>
                </div>
              </div>
              <div className="col-3 px-0">
                <div className="price-card-header">
                  <h3>{plan3.title}</h3>
                  <div className="price-card-tag">
                    <span className="price-tag">$ {plan3.price}</span>
                    <br />
                    <span className="period">{plan3.period}</span>
                  </div>
                  <a href={plan3.orderlink} className="order-btn">
                    Order Now
                  </a>
                </div>
              </div>
              <div className="col-3 px-0">
                <div className="price-card-header">
                  <h3>{plan4.title}</h3>
                  <div className="price-card-tag">
                    <span className="price-tag">$ {plan4.price}</span>
                    <br />
                    <span className="period">{plan4.period}</span>
                  </div>
                  <a href={plan4.orderlink} className="order-btn">
                    Order Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default StickyPlan
