import React,{useState} from 'react'
import { NavLink } from 'react-router-dom';
import {OverlayTrigger, Popover} from 'react-bootstrap'
import './Navbar.css'
import "bootstrap/dist/css/bootstrap.css";
import ISL from "./images/ISL";
import shn from "./images/navigation/shared-hosting-nav.svg"
import whn from "./images/navigation/wordpress-hosting-nav.svg";
import bhn from "./images/navigation/business-hosting-nav.svg";
import $ from "jquery"
const domain_popover = (
<Popover id="domain-popover" style={{position: "absolute",
    inset: "0px auto auto 0px",
    margin: "0px",
    transform: "translate(1003px, 94px)",
    top: "10px,"}}>
    <Popover.Content>
      <ul className="domain-list">
        <li className="domain-link">
          <a href="https://billing.isquarehost.com/cart.php?a=add&domain=register">
            Register New Domain
          </a>
        </li>
        <li className="domain-link">
          <a href="https://billing.isquarehost.com/cart.php?a=add&domain=transfer">
            Trasfer Domain To Us
          </a>
        </li>
      </ul>
    </Popover.Content>
  </Popover>
);

function Navbar() {
    const [click, setClick] = useState(false)
    const [popshow, setPopshow] = useState(false)
    const handleClick = () => setClick(!click)
    const handlepopClick = () => setPopshow(!popshow)
    const closeMobileMenu = () =>{
      setClick(false);
      setPopshow(false);
    }
    const [colorChange, setColorChange] = useState(false)
  const changeNavbarColor = () => {
      if (window.scrollY >= 100) {
        setColorChange(true);
        
    }
      else {
        setColorChange(false);
    }
  }
  window.addEventListener("scroll", changeNavbarColor);
  $(document).mouseup(function (e) {
    var popup = $("#hosting-popover");
    if (
      !$(".mynav-links").is(e.target) &&
      !popup.is(e.target) &&
      popup.has(e.target).length === 0
    ) {
      setPopshow(false);
    }
  });
  
  const hosting_popover = (
    <Popover id="hosting-popover">
      <Popover.Content>
        <ul className="hosting-list">
          <li className="hosting-link">
            <NavLink
              exact
              to="/cheap-hosting"
              className="mynav-links"
              id="mynav-links"
              activeClassName="active"
              onClick={closeMobileMenu}
              data-after="Pricing"
            >
              <img src={shn} alt="shared-hosting-svg" />
              <div>shared hosting</div>
            </NavLink>
          </li>
          <li className="hosting-link">
            <NavLink
              exact
              to="/business-hosting"
              className="mynav-links"
              id="mynav-links"
              activeClassName="active"
              onClick={closeMobileMenu}
              data-after="Pricing"
            >
              <img src={bhn} alt="bussines-hosting-svg" />
              <div>business hosting</div>
            </NavLink>
          </li>
          <li className="hosting-link">
            <NavLink
              exact
              to="/wordpress-hosting"
              className="mynav-links"
              id="mynav-links"
              activeClassName="active"
              onClick={closeMobileMenu}
              data-after="Pricing"
            >
              <img src={whn} alt="wordress-hosting-svg" />
              <div>wordpress hosting</div>
            </NavLink>
          </li>
        </ul>
      </Popover.Content>
    </Popover>
  );
    return (
      <>
        <section id="header">
          <div
            className={
              colorChange ? "header colorChange headerChange" : "header"
            }
          >
            <div className="nav-bar">
              <NavLink exact to="/" className="brand">
                <ISL />
              </NavLink>
              <div className="nav-list">
                <div
                  className={
                    colorChange ? "hamburger colorChange" : "hamburger"
                  }
                  onClick={handleClick}
                >
                  <i
                    className={`fa ${click ? "fa-times" : "fa-bars"} ${
                      colorChange ? "colorChange" : ""
                    } `}
                  />
                </div>
                <ul
                  className={`mynav-menu ${click ? "active" : ""} ${
                    colorChange ? "colorChange" : ""
                  }`}
                >
                  <li className="mynav-item">
                    <OverlayTrigger
                      trigger="click"
                      rootClose
                      placement="bottom"
                      overlay={hosting_popover}
                      show={popshow}
                    >
                      <div
                        className="mynav-links"
                        id="mynav-links"
                        data-after="domain"
                        onClick={handlepopClick}
                      >
                        hosting <i className="fas fa-caret-down"></i>
                      </div>
                    </OverlayTrigger>
                  </li>
                  <li className="mynav-item">
                    <NavLink
                      exact
                      to="/vps-hosting"
                      className="mynav-links"
                      id="mynav-links"
                      activeClassName="active"
                      onClick={closeMobileMenu}
                      data-after="Pricing"
                    >
                      cloud
                    </NavLink>
                  </li>
                  <li className="mynav-item">
                    <NavLink
                      exact
                      to="/hosting"
                      className="mynav-links"
                      id="mynav-links"
                      activeClassName="active"
                      onClick={closeMobileMenu}
                      data-after="Pricing"
                    >
                      pricing & plans
                    </NavLink>
                  </li>
                  <li className="mynav-item">
                    <OverlayTrigger
                      trigger="click"
                      placement="bottom"
                      overlay={domain_popover}
                      rootClose
                    >
                      <div
                        className="mynav-links"
                        id="mynav-links"
                        data-after="domain"
                      >
                        domain <i className="fas fa-caret-down"></i>
                      </div>
                    </OverlayTrigger>
                  </li>
                  <li className="mynav-item login-li">
                    <a
                      href="https://billing.isquarehost.com/login"
                      className="mynav-links login-btn"
                      id="mynav-links"
                    >
                      Login
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </>
    );
}

export default Navbar
