import React from 'react'
import './WhyCloud.css'
import flexibility from '../images/flexibility.webp'
function WhyCloud() {
    return (
      <section id="whyCloud">
        <div className="whyCloud mycontainer">
          <div className="whyCloud-header">
            <h1 className="section-header section-animation" data-aos="fade-up">
              Why Cloud?
            </h1>
          </div>
          <div className="whyCloud-cards">
            <div className="whyCloud-card" data-aos="fade-right">
              <div className="card-image">
                <i className="fas fa-tachometer-alt"></i>
              </div>
              <h3 className="whyCloud-card-header">Performance</h3>
              <p className="card-content">
                All cloud servers using SSD disk well optimized, so its provide
                the high speed to your website
              </p>
            </div>
            <div className="whyCloud-card" data-aos="fade-up">
              <div className="card-image">
                <img src={flexibility} alt="flexibility" />
              </div>
              <h3 className="whyCloud-card-header">Flexible</h3>
              <p className="card-content">
                The cloud offers businesses more flexibility overall versus
                hosting on a local server. And, if you need extra bandwidth, a
                cloud-based service can meet that demand instantly.
              </p>
            </div>
            <div className="whyCloud-card" data-aos="fade-right">
              <div className="card-image">
                <i className="fa  fa-shield-alt"></i>
              </div>
              <h3 className="whyCloud-card-header">Reliable</h3>
              <p className="card-content">
                Realibility is one of the biggest benefits of cloud hosting. You
                can always get instantly updated about the chages.
              </p>
            </div>
          </div>
        </div>
      </section>
    );
}

export default WhyCloud
