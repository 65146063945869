/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect } from 'react'
import "./Contact.css"
function Contact() {
  useEffect(() => {
    window.scrollTo(0,0)
  }, [])
  return (
    <div id="contact">
      <div className="contact mycontainer">
        <div className="contact-header">
          <h1 className="section-header">Contact Us</h1>
          <p>
            Feel free to talk to our online representative at any time you
            please using our Live Chat system on our website or one of the below
            instant messaging programs.
          </p>
        </div>
        <div className="contact-map">
          <div className="contact-box">
            <div className="left">
              <h2>Contact Info</h2>
              <p>
                We are available 24×7 on live chat so you can contact to any
                time for your issues. We will try to provide best support.
              </p>
              <h5>Global Office Address:</h5>
              <h5>USA Address:</h5>
              <p>437 Hackensack St Carlstadt, NJ 07072,USA</p>
              <h5>India Address:</h5>
              <p>
                Raut Colony,Kale Padal, Near Magarpatta City, Hadapsar, Pune
                411028
              </p>
              <h5>Telephone: USA +1-9175876471</h5>
              <p>
                <strong>E-mail:</strong> &nbsp;
                <a href="mailto:support@isquarehost.com">
                  support@isquarehost.com
                </a>
              </p>
              <p>
                <strong>Website:</strong>&nbsp;
                <a href="https://isquarehost.com">www.isquarehost.com</a>
              </p>
            </div>
          </div>
          <div className="google-map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3018.5300438559098!2d-74.09306878530987!3d40.838285179318426!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2f8ef97d97307%3A0x350f18f42507a3b4!2siSquareHost!5e0!3m2!1sen!2sin!4v1621946971352!5m2!1sen!2sin"
              allowFullScreen=""
              loading="lazy"
              className="gmap"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact
