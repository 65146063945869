import React from 'react'
import {
  NavLink,
} from "react-router-dom";
import './PricingNav.css'
function PricingNav() {
    
    return (
      <div className="pricenav mycontainer">
        <div className="pricenav-plans">
          <h1 className="section-header">Pricing and Plans</h1>
          <ul className="pricenav-list">
            <li className="pricenav-item">
              <NavLink
                exact
                to="/hosting/cheap-hosting"
                className="pricenav-links"
                activeClassName="pricenav-active"
              >
                <h3>Shared hosting</h3>
                <p>Best for Websites</p>
              </NavLink>
            </li>
            <li className="pricenav-item">
              <NavLink
                exact
                to="/hosting"
                className="pricenav-links"
                activeClassName="pricenav-active"
              >
                <h3>business hosting</h3>
                <p>LiteSpeed Shared hosting</p>
              </NavLink>
            </li>
            <li className="pricenav-item">
              <NavLink
                exact
                to="/hosting/wordpress-hosting"
                className="pricenav-links"
                activeClassName="pricenav-active"
              >
                <h3>Wordpress hosting</h3>
                <p>Fully Managed and Optimized WordPress solution</p>
              </NavLink>
            </li>
            <li className="pricenav-item">
              <NavLink
                exact
                to="/hosting/vps-hosting"
                className="pricenav-links"
                activeClassName="pricenav-active"
              >
                <h3>cloud vps hosting</h3>
                <p>private ssd based cloud servers</p>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    );
}

export default PricingNav
