import React, { useEffect } from "react";
// import { OverlayTrigger, Popover } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import PriceCard from "./PriceCard";
import "./Price.css";
import "./BusinessPrice.css";
import StickyPlan from "./StickyPlan";
import "./WordpressPrice.css";
function DedicatedPrice() {
  useEffect(() => {
    var goto = document.getElementById("wordpress").offsetTop;
    window.scrollTo({ top: goto - 100, behavior: "smooth" });
  }, []);
  return (
    <div id="wordpress">
      <StickyPlan
        plan1={{
          title: "WP 1",
          price: "14.99",
          period: "Monthly",
          orderlink: "https://billing.isquarehost.com/cart.php?a=add&pid=80",
        }}
        plan2={{
          title: "WP 2",
          price: "24.99",
          period: "Monthly",
          orderlink: "https://billing.isquarehost.com/cart.php?a=add&pid=81",
        }}
        plan3={{
          title: "WP 3",
          price: "34.99",
          period: "Monthly",
          orderlink: "https://billing.isquarehost.com/cart.php?a=add&pid=82",
        }}
        plan4={{
          title: "WP 4",
          price: "44.99",
          period: "Monthly",
          orderlink: "https://billing.isquarehost.com/cart.php?a=add&pid=83",
        }}
      />
      <div className="wordpress">
        <div className="wordpress-header">
          <h2>Fully Managed and Optimized WordPress solution</h2>
          <p>
            WordPress is Content Management System(CMS) and you can create your
            personal blog, business website or online store at just one click
          </p>
        </div>
        <div className="row m-0">
          <div className="col-xl-12 col-12 p-0">
            <div className="row m-0 wordpress-cards">
              <div className="col-md-3 col-12 mx-0 p-1">
                <PriceCard
                  cardHeader="WP 1"
                  cardSubheader="best performance for single wordpress website"
                  cardPrice="14.99"
                  period="Monthly"
                  priceOffer={[
                    "fully optimized wordpress",
                    "1 wordpress install ",
                    "5 GB SSD space",
                    "50 GB bandwidth",
                    "2,50,000 monthly visits",
                    "free SSL, CDN, WFA",
                    "free staging install",
                    "SSH access",
                    "GIT integration",
                    "uptime guarantee 99.99%",
                  ]}
                  orderlink="https://billing.isquarehost.com/cart.php?a=add&pid=80"
                />
              </div>
              <div className="col-md-3 col-12 mx-0 p-1">
                <PriceCard
                  cardHeader="WP 2"
                  cardSubheader="best performance for multiple wordpress website "
                  cardPrice="24.99"
                  period="Monthly"
                  priceOffer={[
                    "fully optimized wordpress",
                    "3 wordpress install ",
                    "15 GB SSD space",
                    "100 GB bandwidth",
                    "10,00,000 monthly visits",
                    "free SSL, CDN, WFA",
                    "free staging install",
                    "SSH access",
                    "GIT integration",
                    "uptime guarantee 99.99%",
                  ]}
                  orderlink="https://billing.isquarehost.com/cart.php?a=add&pid=81"
                />
              </div>
              <div className="col-md-3 m-0 p-1">
                <PriceCard
                  cardHeader="WP 3"
                  cardSubheader="more visitors and storage for multiple wordpress website"
                  cardPrice="34.99"
                  period="Monthly"
                  priceOffer={[
                    "fully optimized wordpress",
                    "10 wordpress install ",
                    "30 GB SSD space",
                    "300 GB bandwidth",
                    "25,00,000 monthly visits",
                    "free SSL, CDN, WFA",
                    "free staging install",
                    "SSH access",
                    "GIT integration",
                    "uptime guarantee 99.99%",
                  ]}
                  orderlink="https://billing.isquarehost.com/cart.php?a=add&pid=82"
                />
              </div>
              <div className="col-md-3 m-0 p-1">
                <PriceCard
                  cardHeader="WP 4"
                  cardSubheader="everything unlimited for unlimited websites"
                  cardPrice="44.99"
                  period="Monthly"
                  priceOffer={[
                    "fully optimized wordpress",
                    "unlimited wordpress install ",
                    "unlimited GB SSD space",
                    "unlimited bandwidth",
                    "unlimited monthly visits",
                    "free SSL, CDN, WFA",
                    "free staging install",
                    "SSH access",
                    "GIT integration",
                    "uptime guarantee 99.99%",
                  ]}
                  orderlink="https://billing.isquarehost.com/cart.php?a=add&pid=83"
                />
              </div>
            </div>
          </div>
          {/* Hosting Features Table */}
          {/* <div className="col-12 m-0">
            <div className="table-responsive-lg">
              <table className="table table-bordered plans-table">
                <tbody>
                  <tr className="title-row">
                    <td colSpan="4">
                      <div className="h4">Hosting Featires</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div> */}
          {/* Hosting Features Table end */}
        </div>
      </div>
    </div>
  );
}

export default DedicatedPrice;
