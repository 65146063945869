import React, { Component } from 'react'
import './PriceCard.css'
export class PriceCard extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             offer:this.props.priceOffer,
        }
    }
    
    render() {
        return (
          <div className="price-card">
            {/* <div className="powered">
              <h4>Powered by</h4>
              <div className="powered-img">
                <img src="https://img.icons8.com/color/50/000000/amazon-web-services.png" alt="aws" />
              </div>
            </div> */}
            <div className="price-card-header">
              <h3>{this.props.cardHeader}</h3>
              <p>{this.props.cardSubheader}</p>
              <div className="price-card-tag">
                <span className="price-tag">$ {this.props.cardPrice}</span>
                <br />
                <span className="period">{this.props.period}</span>
              </div>
            </div>

            <div className="price-card-content">
              <ul>
                {this.state.offer &&
                  this.state.offer.map((o, index) => {
                    return <li key={index}>{o}</li>;
                  })}
              </ul>
            </div>
            
              <a
                href={this.props.orderlink}
                className="order-btn"
              >
                Order Now
              </a>

          </div>
        );
    }
}

export default PriceCard

