import React from 'react'
import './OurServices.css'
function OurServices() {
    return (
      <section id="services">
        <div className="services container">
          <div className="services-headers">
            <div className="services-header">
              <h1 className="section-header">our services</h1>
            </div>
            <div className="services-header-content">
              <p>Shared Hosting on Cloud Server with Best cost</p>
            </div>
          </div>
          <div className="services-content">
            <div className="services-cards">
              <div
                className="services-card"
                data-aos="fade-up"
                data-aos-anchor-placement="center-bottom"
                data-aos-delay="0"
              >
                <div className="service-card-header">
                  <img
                    src="https://img.icons8.com/ios/64/000000/domain.png"
                    alt="domain"
                  />
                  <h3>domain services</h3>
                </div>
                <div className="card-content">
                  <ul className="card-content-list">
                    <li>Domain Registraion</li>
                    <li>Domain Renewal</li>
                    <li>Domain Transfer</li>
                    <li>Domaim Privacy Protection</li>
                  </ul>
                </div>
              </div>
              <div
                className="services-card"
                data-aos="fade-up"
                data-aos-anchor-placement="center-bottom"
                data-aos-delay="100"
              >
                <div className="service-card-header">
                  <img
                    src="https://img.icons8.com/ios-filled/64/000000/cloud-computing.png"
                    alt="cloud computing"
                  />
                  <h3>business Hosting</h3>
                </div>
                <div className="card-content">
                  <ul className="card-content-list">
                    <li>All Servers with SSD Storage</li>
                    <li>highly secured and Fast Service</li>
                    <li>Cost Saving</li>
                    <li>Free Migrations</li>
                  </ul>
                </div>
              </div>
              <div
                className="services-card"
                data-aos="fade-up"
                data-aos-anchor-placement="center-bottom"
              >
                <div className="service-card-header">
                  <img
                    src="https://img.icons8.com/windows/64/000000/wordpress-simple.png"
                    alt="wordpress cloud"
                  />
                  <h3>wordpress Hosting</h3>
                </div>
                <div className="card-content">
                  <ul className="card-content-list">
                    <li>Optimized Wordpress</li>
                    <li>efforless wordpress</li>
                    <li>mobile accelerations</li>
                    <li>image optimizations</li>
                    <li>faster than shared hosting</li>
                  </ul>
                </div>
              </div>
              <div
                className="services-card"
                data-aos="fade-up"
                data-aos-anchor-placement="center-bottom"
              >
                <div className="service-card-header">
                  <img
                    src="https://img.icons8.com/pastel-glyph/64/000000/cloud-development--v1.png"
                    alt="vps"
                  />
                  <h3>cloud vps Hosting</h3>
                </div>
                <div className="card-content">
                  <ul className="card-content-list">
                    <li>Instant Scaling</li>
                    <li>Fast Load Time</li>
                    <li>Flexible than Shared Cloud Hosting</li>
                    <li>More Privileges</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}
export default OurServices