import React, { Component } from "react";
import Price from "../Pricing/Price";
import HeroSection from "./HeroSection";
import OurServices from "./OurServices";
import WeOffer from "./WeOffer";
import WhyCloud from "./WhyCloud";

export class Home extends Component {
  componentDidMount() {
    window.scrollTo(0,0)
  }
  render() {
    return (
      <div id="home">
        <HeroSection />
        <Price />
        <WhyCloud />
        <WeOffer />
        <OurServices />
      </div>
    );
  }
}

export default Home;
