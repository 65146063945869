import React,{useEffect} from 'react'
import './About.css'
import AroundWorld from "../images/around-the-world.svg"
import Domain from "../images/domain.svg";
import CustomerSat from "../images/customer-sat.svg";
function About() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
      <div id="about">
        <div className="about mycontainer">
          <div className="about-header">
            <h1 className="section-header">About Us</h1>
            <p>
              At iSquareHost our goal is web hosting should be fast, reliable
              and affordable. We love what we do and always enjoy making our
              customers happy. iSquareHost currently offers a wide range of Web
              hosting products, email solutions, high-end servers and services.
              Web hosting services provided by iSquareHost comprises of
              technology, uptime and 24x7x365 elite customer support.
            </p>
          </div>
          <div className="what-we-are">
            <div className="header">
              <h3>what we are</h3>
              <p>
                We are a bundle of enthusiastic and exuberant people, who came
                from different background and made iSquarehost a “family”. Our
                team is a reflection of our values and ethics that are followed
                for providing support and solutions to our customers
                wholeheartedly.
              </p>
            </div>
            <div className="what-we-are-cards">
              <div className="what-we-are-card">
                <img src={AroundWorld} alt="Around the World" />
                <h4>world's largest</h4>
                <p>We compete the worlds largest website hosting providers.</p>
              </div>
              <div className="what-we-are-card">
                <img src={Domain} alt="Domain" />
                <h4>Lots of domains</h4>
                <p>We have lots of customer and domains are hosted</p>
              </div>
              <div className="what-we-are-card">
                <img src={CustomerSat} alt="Domain" />

                <h4>satisfied customer</h4>
                <p>
                  We have number of satisfied customers and every they are
                  increasing
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default About
